.confirmation-view {
    background-image:
        url(./../../assets/images/back.webp),
        radial-gradient(31% 50% at 0% 100%, #0d2757 0%, #03102D 150%),
        linear-gradient(180deg, #030c1a 1%, #030c1a 100%);

    background-size: cover;

    &__container {
        width: 100%;
        height: 100%;
        background:
            radial-gradient(31% 50% at 0% 0%, rgba(13, 39, 87, 0.6) 0%, rgba(3, 16, 45, 0.6) 150%),
            linear-gradient(180deg, rgba(3, 12, 26, 0.2) 1%, rgba(3, 12, 26, 0.2) 100%);
    }
}