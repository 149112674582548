@import './../../styles/main';

/* Estilos generales */
.login-component {
  background-image: linear-gradient(to right, #a95c73, #59495e);
  display: flex;
  justify-content: center;
  // align-items: center;
  height: 100vh;
}

.login-form {
  padding: 30px;
  max-width: 700px;
  width: 100%;

  &__logo {
    max-width: 100%;
    max-height: 200px;
    margin: 0 auto 20px;
    display: block;
  }

  &__title {
    text-align: center;
    margin-bottom: 20px;
    color: #e9bf95;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__group {
    margin-bottom: 20px;
  }

  &__label {
    display: block;
    margin-bottom: 5px;
  }

  &__input {
    border: none;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;

    &::placeholder {
      color: #c6c6c6;
    }

    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }

  &__submit-btn {
    background-color: #ea6793;
    border: none;
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    width: 100%;

    &:hover {
      background-color: #f82c6d;
    }
  }
}

/* Estilos para dispositivos de tamaño grande */
@include breakpoint(bp-large) {
  .login-form {
    padding: 50px;

    &__logo {
      max-height: 300px;
    }

    &__title {
      font-size: 2.5rem;
      margin-bottom: 30px;
    }

    &__input {
      padding: 12px;
      font-size: 1.1rem;
    }

    &__submit-btn {
      font-size: 1.1rem;
      padding: 12px 24px;
    }
  }
}

/* Estilos para dispositivos de tamaño mediano */
// @media only screen and (min-width: 600px) and (max-width: 1023px) {
@include breakpoint(bp-medium) {
  .login-form {
    padding: 40px;

    &__logo {
      max-height: 250px;
    }

    &__title {
      font-size: 2rem;
      margin-bottom: 25px;
    }

    &__input {
      padding: 10px;
      font-size: 1rem;
    }

    &__submit-btn {
      font-size: 1rem;
    }
  }
}

/* Estilos para dispositivos móviles */
@include breakpoint(bp-small) {
  .login-form {
    padding: 20px;

    &__logo {
      max-height: 150px;
    }

    &__title {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }

    &__input {
      padding: 8px;
      font-size: 0.9rem;
    }

    &__submit-btn {
      font-size: 0.9rem;
    }
  }
}