@tailwind base;
@tailwind components;
@tailwind utilities;

.install {
  position: relative;
}

.link-docs a {
  @apply text-primary underline cursor-pointer
}