@import './../../styles/main';

.home-view {
  background-image:
    url(./../../assets/images/back.webp),
    radial-gradient(31% 50% at 0% 100%, #0d2757 0%, #03102D 150%),
    linear-gradient(180deg, #030c1a 1%, #030c1a 100%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;

  &__form {
    width: 100%;
  }
}

/* Estilos para dispositivos móviles */
@include breakpoint(bp-small) {
  .pink-flower__left {
    position: absolute;
    top: -43px;
    left: -50px;
    width: 190px;
    height: unset;
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}