@import './../../styles/main';

.conditions {
  background-image:
    // radial-gradient(31% 50% at 0% 100%, #0d2757 0%, #030c1a 150%),
    linear-gradient(180deg, #030c1ad4 1%, #030c1a88 100%),
    url(./../../assets/images/back.webp);
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  height: auto;
  width: 100%;
  justify-content: space-between;
  gap: 5rem;
}