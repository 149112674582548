@import './../../styles/main';

.contact-form {
  background-image:
    // radial-gradient(31% 50% at 0% 100%, #0d2757 0%, #030c1a 150%),
    linear-gradient(180deg, #030c1ad4 1%, #030c1a88 100%),
    url(./../../assets/images/back.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  padding: 10rem 0 0 8rem;
  gap: 1.5rem;
  position: relative;
  z-index: 1;

  &__img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 500px;
    height: auto;
    z-index: -1;
    pointer-events: none;
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;



    input {
      border: none;
      border-radius: 12px;
      cursor: pointer;
      font-size: 1.2rem;
      color: white;
      background-color: rgba(136, 136, 136, 0.095);
      backdrop-filter: blur(5px);
    }

    textarea {
      border: none;
      border-radius: 12px;
      cursor: pointer;
      font-size: 1.2rem;
      color: white;
      min-height: 120px;
      background-color: rgba(136, 136, 136, 0.095);
      backdrop-filter: blur(5px);
    }
  }

  &__button {
    display: flex;
    justify-content: center;

    &-btn {
      border: 3px solid #e1aac6;
      border-radius: 12px;
      color: aliceblue;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      padding: 3px 15px;
      text-transform: uppercase;
      background-color: #a0586f;

      &:hover {
        border: 3px solid #ed88bc;
        background-color: #d44877;
      }
    }
  }
}

/* Estilos para dispositivos de tamaño extra extra grande */
@include breakpoint(bp-xxl) {
  .contact-form {
    padding: 10rem 6rem;
  }
}

/* Estilos para dispositivos de tamaño mediano */
@include breakpoint(bp-medium) {
  .contact-form {
    padding: 10rem 4rem;
  }
}

/* Estilos para dispositivos móviles */
@include breakpoint(bp-small) {
  .contact-form {
    padding: 6rem 1rem;
    height: 100vh;

    &__data {
      width: 100%;
    }
  }
}