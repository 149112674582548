@import '../../../styles/main';

/* Estilos generales */
.login-page-home {
  background:
    radial-gradient(42% 57% at 98% 10%, rgba(89, 73, 94, 0.6) -22%, transparent 100%),
    linear-gradient(180deg, rgba(169, 92, 115, 0.6) 1%, rgba(3, 16, 45, 0.6) 100%);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  &__pink-flower__left {
    position: absolute;
    top: -165px;
    left: -192px;
    height: 570px;
    z-index: 0;
    object-fit: contain;
    pointer-events: none;
  }

  &__pink-flower__right {
    position: absolute;
    top: -165px;
    right: -192px;
    height: 570px;
    transform: scaleX(-1);
    z-index: 0;
    object-fit: contain;
    pointer-events: none;
  }
}

/* Estilos para dispositivos de tamaño extra grande */
@include breakpoint(bp-xxl) {
  .login-page-home {

    &__pink-flower__left {
      position: absolute;
      top: -129px;
      left: -150px;
      height: 450px;
      z-index: 0;
      object-fit: contain;
    }

    &__pink-flower__right {
      position: absolute;
      top: -129px;
      right: -150px;
      height: 450px;
      z-index: 0;
      object-fit: contain;
    }
  }
}

/* Estilos para dispositivos móviles */
@include breakpoint(bp-large) {
  .login-page-home {
    &__pink-flower__left {
      position: absolute;
      top: -64px;
      left: -80px;
      height: 300px;
      z-index: 0;
      object-fit: contain;
    }

    &__pink-flower__right {
      position: absolute;
      top: -64px;
      right: -80px;
      height: 300px;
      z-index: 0;
      object-fit: contain;
    }
  }
}

/* Estilos para dispositivos móviles */
@include breakpoint(bp-small) {
  .login-page-home {
    &__pink-flower__left {
      position: absolute;
      top: -61px;
      left: -72px;
      height: 220px;
      z-index: 0;
      object-fit: contain;
    }

    &__pink-flower__right {
      position: absolute;
      top: -61px;
      right: -72px;
      height: 220px;
      transform: scaleX(-1);
      z-index: 0;
      object-fit: contain;
    }
  }
}