@import './variables';

@mixin breakpoint($point) {
    @if $point == bp-small {
        @media (max-width: $bp-small) {
            @content;
        }
    }
    @else if $point == bp-medium {
        @media (max-width: $bp-medium) {
            @content;
        }
    }
    @else if $point == bp-large {
        @media (max-width: $bp-large) {
            @content;
        }
    }
    @else if $point == bp-xl {
        @media (max-width: $bp-xl) {
            @content;
        }
    }
    @else if $point == bp-xxl {
        @media (max-width: $bp-xxl) {
            @content;
        }
    }
}