// break points
$bp-small: 639.9808px;
$bp-medium: 767.9808px;
$bp-large: 1023.9808px;
$bp-xl: 1279.9808px;
$bp-xxl: 1538.9808px;


// $backgroundColor: #282c34;
// $backgroundColor: #000;
// $font-color: #fff;

//----COLORS----
// $primary-color: #ff5fa3;
$primary-color: #be6a94;
$secondary-color: #ff0000;

$light-color: #ffffff;

$gray-light: #F0F2F4;
$normal-gray: #505050;
$dark-gray: #595d5e;

$backgroundColor: $gray-light;
$font-color: $dark-gray;