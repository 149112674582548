@import './../../../styles/main';

.registration-form {
  background:
    radial-gradient(31% 50% at 0% 0%, rgba(13, 39, 87, 0.6) 0%, rgba(3, 16, 45, 0.6) 150%),
    linear-gradient(180deg, rgba(3, 12, 26, 0.3) 1%, rgba(3, 12, 26, 0.3) 100%);
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;
  width: 100%;
  position: relative;

  &__adanEva {
    position: absolute;
    bottom: -32px;
    right: -62px;
    z-index: 0;
    width: 100%;
    max-width: 780px;
    height: auto;

  }

  &__items {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    gap: 1rem;
    z-index: 1;
  }

  &__option {
    border: 1px solid #5c6983;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 1.2rem;
    color: white;
    padding: 0.2rem 1.2rem;

    &.selected {
      background-color: $primary-color;
      border: 1px solid #5c6983;
    }
  }

  &__relation {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    p {
      color: #fff;
      font-weight: 500;
      font-size: 1.2rem;
      text-align: left;
    }

    &-btn {
      display: flex;
      gap: 1rem;
    }

    &-question {
      color: #fff;
      font-weight: 500;
      font-size: 1.2rem;
    }
  }

  &__basics {
    display: flex;
    gap: 1rem;
    justify-content: center;
    position: relative;

    &-moreInfo {
      position: absolute;
      bottom: -30px;
      left: 0;
      color: brown;
    }

    &-am,
    &-look {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 150px;

      p {
        color: #fff;
        font-weight: 500;
        font-size: 1.2rem;
        text-align: left;
        margin-left: 1rem;
        white-space: nowrap;
      }

    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2.5rem;

    &-input {
      display: flex;
      gap: 1rem;
      justify-content: space-between;

      label {
        color: #fff;
        font-weight: 500;
        font-size: 1.2rem;
        text-align: left;
        margin-left: 1rem;
        white-space: nowrap;
      }

      input,
      select {
        border: 1px solid #5c6983;
        border-radius: 12px;
        cursor: pointer;
        font-size: 1.2rem;
        color: white;
        padding: 0.2rem 1.2rem;
        background-color: rgba(0, 0, 0, 0.5);
        width: 300px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 0.2rem;

      p {
        color: crimson;
      }
    }
  }

  &__submit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;

    &-label {
      display: flex;

      label {
        color: #fff;
        font-weight: 300;
        font-size: 0.8125rem;
        margin-left: 1rem;

        a {
          color: $primary-color;
          text-decoration: underline;
        }
      }
    }

    &-button {
      width: 100%;
      display: flex;
      justify-content: center;

      .registration-form__submit-btn {
        border: 3px solid #e1aac6;
        border-radius: 12px;
        color: aliceblue;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        padding: 3px 15px;
        text-transform: uppercase;
        background-color: #a0586f;
        width: 240px;

        &:hover {
          border: 3px solid #ed88bc;
          background-color: #d44877;
        }
      }
    }
  }

  input[type="checkbox"] {
    border: 1px solid #5c6983;
    border-radius: 5px;
    background-color: transparent;
    height: 1.4rem;
    width: 1.4rem;
    color: $primary-color;
  }
}

.width {
  select {
    width: unset;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1) grayscale(1) brightness(2);
  /* Otros estilos para el ícono aquí, si es necesario */
}

/* Estilos para dispositivos de tamaño extra extra grande */
@include breakpoint(bp-xxl) {
  .registration-form {
    &__adanEva {
      max-width: 680px;
    }
  }
}

/* Estilos para dispositivos de tamaño grande */
@include breakpoint(bp-large) {
  .registration-form {

    &__items {
      width: unset;
    }
  }
}

/* Estilos para dispositivos móviles */
@include breakpoint(bp-small) {

  .registration-form {
    height: auto;
    gap: 0.5rem;

    &__items {
      gap: 3rem;
    }

    &__data {
      width: 100%;
      margin-top: 1rem;

      &-input {
        flex-direction: column;
        gap: 0.2rem;
        align-items: flex-start;

        input {
          width: 100%;
        }

        p {
          font-size: 1rem;
          white-space: nowrap;
        }
      }

      &-content {
        width: 100%;

        select {
          width: 100%;
        }
      }
    }

    &__submit {
      width: 100%;

      &-label {
        label {
          font-size: 0.9rem;
        }
      }
    }

    &__option {
      font-size: 1rem;
    }
  }
}