@import '../../styles/main';

.support-chat {
  position: fixed;
  z-index: 1000;
  bottom: 90px; // Ajusta según la altura del botón
  right: 20px;
  width: 380px;
  height: calc(100vh - 190px);
  max-height: none;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(100%);
  opacity: 0;

  &--active {
    transform: translateY(0);
    opacity: 1;
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    background-color: $primary-color;
    color: white;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 1.2rem;
    text-align: center;

    &-image {
      width: 40px;
      height: 40px;
      object-fit: contain;
      border-radius: 50%;
      margin-right: 15px;
    }

    &-title {
      font-size: 15px;
      font-weight: 600;
      white-space: nowrap;
      min-width: 0;
      color: #fff;
    }
  }

  &__messages {
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: auto;
    max-height: calc(100vh - 252px);

    .message {
      max-width: 80%;
      padding: 10px;
      margin: 10px 0;
      border-radius: 12px;

      p {
        white-space: pre-wrap;
        overflow-wrap: break-word;
        font-size: 19px;
        line-height: 22px;
        text-align: left;
        color: #101010;
      }

      &--bot {
        position: relative;
        background-color: #f0f0f0;
        align-self: flex-start;
      }

      &--user {
        background-color: $primary-color;
        color: white;
        align-self: flex-end;

        p {
          color: white;
        }
      }

      .message__label {
        position: absolute;
        top: -13px;
        left: 10px;
        background-color: $primary-color;
        color: white;
        padding: 2px 5px;
        border-radius: 5px;
        font-size: 0.75rem;
      }
    }
  }

  &__options {
    display: flex;
    flex-flow: wrap;
    gap: 10px;
    justify-content: flex-end;
  }

  &__button {
    border: 3px solid $primary-color;
    border-radius: 12px;
    color: $primary-color;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 3px 15px;
    // text-transform: uppercase;

    &:hover {
      color: #ef6893;
      border: 3px solid #ef6893;
    }
  }

}

.chat-input {
  padding: 10px;
  /* Estilos para el campo de entrada aquí */
}

/* Estilo del botón de soporte */
.support-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: $primary-color;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 28px;
  cursor: pointer;
  z-index: 1000;
  border: 2px solid white;
  /* Asegura que esté en la parte superior */
}

.support-button.bounce {
  animation: bounce 0.5s ease;
}

/* Estilos para dispositivos móviles */
@include breakpoint(bp-small) {

  .support-chat {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;

    &__header {
      border-radius: unset;
    }

    &__messages {
      max-height: calc(100vh - 100px);
    }
  }
}


.typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing-indicator span {
  background-color: $primary-color;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 3px;
  animation: typing 1.5s infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.support-chat__button {
  animation: zoomIn 1s ease;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.support-chat__button {
  /* tus estilos existentes para el botón */
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}