@import '../../styles/main';

.user-section {

    min-width: 300px;
    background-image: linear-gradient(73deg, #a95c73,#92576c);
    padding: 1rem;

    &__item {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      // margin-right: 0.5rem;
  
      &.active {
        background-color: #b1677e;
        border-radius: 10px;
      }
    }
  
    &__item-image {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 15px;
      margin-left: 10px;
    }
  
    &__item-title {
      font-size: 15px;
      font-weight: 600;
      white-space: nowrap;
      min-width: 0;
      color: #fff;
    }
  }

/* Estilos para dispositivos móviles */
@include breakpoint(bp-small) {
  .user-section {
    &__item {
      &.active{
        border-radius: unset;
      }
    }

    &__item-image { 
      margin-left: 6px;
    }
  }
}