@import '../../styles/main';

.chat {
    display: flex;
    height: 100vh;
    // background-image: linear-gradient(73deg, #a95c73, #92576c);
    background-image: linear-gradient(73deg, #bbadb2, #ff7fac);
    padding: 0;
    margin: 0;

    &__content {
      display: flex;
      width: 100%;

      &-users {
        flex-basis: 30%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        min-width: 300px;
        background-image: linear-gradient(73deg, #bbadb2, #d79aaf);

          &--logo {
            max-width: 100%;
            max-height: 5.5rem;
            display: block;
            margin-right: 15px;
          }

          &--title {
            text-align: center;
            color: #e9bf95;
            text-transform: uppercase;
            font-weight: 600;
            white-space: nowrap;
          }

          &--scrollable {
            max-height: calc(100vh - 140px); /* Ajusta la altura según tus necesidades */
            overflow-y: auto;
            padding-right: 10px;

            /* Estilos para la barra de desplazamiento vertical */
            &::-webkit-scrollbar {
              width: 4px; /* Ancho de la barra de desplazamiento */
            }
          
            &::-webkit-scrollbar-track {
              background-color: transparent; /* Color de fondo de la pista de la barra de desplazamiento */
            }
          
            &::-webkit-scrollbar-thumb {
              background-color: rgba($color: #000, $alpha: 0.3); /* Color del pulgar de la barra de desplazamiento */
              border-radius: 4px; /* Radio de borde del pulgar de la barra de desplazamiento */
            }
          
            &::-webkit-scrollbar-thumb:hover {
              background-color: rgba($color: #000, $alpha: 0.5); /* Color del pulgar de la barra de desplazamiento al pasar el cursor */
            }
            
          }
      }
    }

  }

  .mobile {
    width: 100%;
    flex-basis: unset;
  }

/* Estilos para dispositivos móviles */
@include breakpoint(bp-small) {
  .chat {
    &__content {
      &-users {
        padding: 0.5rem 0;
        &--scrollable {
          padding-right: unset;
        }
      }
    }
  }
}