.logo-component {
    max-width: 25rem;
    width: 100%;
  
    &__logo {
        max-width: 100%;
        max-height: 12.5rem;
        margin: auto;
        display: block;
    }
      
    &__title {
        text-align: center;
        margin-bottom: 1.25rem;
        color: #e9bf95;
        text-transform: uppercase;
        font-weight: 500;
        white-space: nowrap;
    }
}

/* Estilos para dispositivos móviles */
@media only screen and (max-width: 599px) {
    .logo-component {
      &__logo {
        max-height: 9.375rem;
      }

      &__title {
        font-size: 1.5rem;
        margin-bottom: 0.9375rem;
      }
    }
  }
  
  /* Estilos para dispositivos de tamaño mediano */
  @media only screen and (min-width: 600px) and (max-width: 1023px) {
    .logo-component {
        &__logo {
            max-height: 15.625rem;
        }
  
        &__title {
            font-size: 2rem;
            margin-bottom: 1.5625rem;
        }
      }
  }
  
  /* Estilos para dispositivos de tamaño grande */
  @media only screen and (min-width: 1024px) {
    .logo-component {
        &__logo {
            max-height: 18.75rem;
        }
  
        &__title {
            font-size: 2.2rem;
            margin-bottom: 1.875rem;
        }
      }
}