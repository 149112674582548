.chat-section {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    // background-color: #a95c73;
    background-color: #d59aac;
    height: 60px;
    padding: 1rem;
    justify-content: space-between;

    &-info {
      display: flex;
      align-items: center;
    }

    &-image {
      width: 40px;
      height: 40px;
      object-fit: contain;
      border-radius: 50%;
      margin-right: 15px;
    }

    &-title {
      font-size: 15px;
      font-weight: 600;
      white-space: nowrap;
      min-width: 0;
      color: #fff;
    }

    &-close {
      width: 30px;
      height: 30px;

      img {
        transform: rotate(180deg);
      }
    }
  }

  &__messages {
    flex: 1;
    overflow-y: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-image:
      url(./../../assets/images/back.webp),
      linear-gradient(90deg, rgba(138, 69, 92, 0.1), rgba(84, 68, 89, 0.1));
  }

  &__message {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
  }

  &__message--sent {
    background-color: #dcf8c6;
    align-self: flex-end;
  }

  &__message-text {
    color: #000;
    font-size: 14px;
  }

  &__input {
    display: flex;
    align-items: center;
    padding: 10px;
    // background-color: #a95c73;
    background-color: #cda1ae;

    input {
      flex: 1;
      height: 40px;
      border-radius: 10px;
      border: none;
      padding: 0 15px;
      font-size: 14px;
      background-color: #b76880;

      &::placeholder {
        color: #ffF;
      }
    }

    button {
      right: 10px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin-left: 10px;
      background-image: url('./../../assets/images/send.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .back-button {
    width: 15px;
    margin-right: 1.5rem;
  }

  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    &__messages {
      height: calc(100% - 80px);
    }
  }
}