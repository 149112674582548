@import './../../styles/main';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  height: 105px;
  transition: all 0.5s ease-in-out;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);

  &__logo {
    z-index: 15;

    img {
      height: 60px;
      width: 60px;
      object-fit: contain;
      transition: all 0.5s ease-in-out;
    }

    &-link {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: bold;
    color: $primary-color;
    text-transform: uppercase;
    line-height: 1;
    margin-left: 0.5rem;
    white-space: nowrap;
    transition: all 0.5s ease-in-out;
  }

  &-hidden {
    transform: translateY(-100%);
  }

  &-visible {
    transform: translateY(0%);

  }

  &-menu {
    display: flex;

    &.opened {
      opacity: 1;
      pointer-events: all;
    }

    ul {
      display: flex;
      gap: 1.875rem;
      margin: 0;
    }

    li {
      text-align: center;
      color: #d3b1c2; // color: #be6a94;
      font-size: 1.5rem;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        color: #ef6893;
      }
    }

    &__login-btn {
      border: 3px solid #d3b1c2;
      ;
      border-radius: 12px;
      color: #d3b1c2; //color: #be6a94;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      padding: 3px 15px;
      text-transform: uppercase;

      &:hover {
        color: #ef6893;
        border: 3px solid #ef6893;
      }

      @include breakpoint(bp-large) {
        margin-top: 1rem;
      }
    }
  }


}

.opened {
  &::before {
    transform: rotate(45deg) translate(3px, 4px);
  }

  &::after {
    transform: rotate(-45deg) translate(3px, -4px);
  }
}



@include breakpoint(bp-xxl) {
  .navbar {
    &-menu {
      li {
        font-size: 1.2rem;
      }
    }
  }
}

@include breakpoint(bp-large) {
  .navbar {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);

    &-menu {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background: #191A36;
      overflow-x: hidden;
      overflow-y: auto;
      transition: opacity 0.5s ease;
      opacity: 0;
      pointer-events: none;
      z-index: 10;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      list-style: none;
      margin: 0;
      padding: 0;
      padding-top: 80px;

      ul {
        flex-direction: column;
      }
    }

    &-toggle {
      height: 48px;
      position: relative;
      width: 32px;
      z-index: 11;

      &::before {
        background: $primary-color;
        content: '';
        height: 2px;
        left: 2px;
        position: absolute;
        right: 2px;
        transition: all 0.3s ease;
        top: 18px;
      }

      &::after {
        background: $primary-color;
        content: '';
        height: 2px;
        left: 2px;
        position: absolute;
        right: 2px;
        transition: all 0.3s ease;
        bottom: 18px;
      }
    }
  }
}

@include breakpoint(bp-medium) {
  .navbar {
    height: 80px;
    padding: 2rem 1rem;

    &__logo {
      img {
        height: 50px;
        width: 50px;
        object-fit: contain;
      }
    }

    &__title {
      font-size: 1rem;
    }
  }
}