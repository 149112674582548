@import './../../../styles/main';

.question {
  background:
    radial-gradient(31% 50% at 0% 100%, rgba(13, 39, 87, 0.6) 0%, rgba(3, 16, 45, 0.6) 150%),
    linear-gradient(180deg, rgba(3, 12, 26, 0.3) 1%, rgba(3, 12, 26, 0.3) 100%);
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  position: relative;

  &__blueSheet3 {
    position: absolute;
    bottom: -218px;
    left: -146px;
    z-index: 1;
  }

  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 2;
    gap: 2rem;
  }

  &__questions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3rem;
    flex: 1;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: -17px;
      left: 0px;
      width: 67px;
      height: 20px;
      border-top: 5px solid rgb(255, 251, 191);
      border-left: 5px solid rgb(255, 251, 191);
    }

  }

  &__title {
    font-size: 1.2rem;
    font-weight: bold;
    color: $primary-color;
    text-transform: uppercase;
    line-height: 1;
    margin-left: 0.5rem;
    white-space: nowrap;
    position: relative;


  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: baseline;

  }

  &__content {
    font-size: 1.375rem;
    line-height: 1.5;
    color: #fff;
    text-align: justify;
    margin-left: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }

  &__content--clamp {
    -webkit-line-clamp: unset;
  }

  &__toggle-btn {
    background-color: transparent;
    border: none;
    color: $primary-color;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
  }
}

.animate-heartbeat {
  animation: heartbeat 3s infinite;
}

@keyframes heartbeat {

  0%,
  100% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.91);
  }

  20%,
  40%,
  60% {
    transform: scale(1.05);
  }

  30%,
  50%,
  70% {
    transform: scale(0.95);
  }

  80% {
    transform: scale(1.05);
  }

  90% {
    transform: scale(0.95);
  }
}

/* Estilos para dispositivos de tamaño extra extra grande */
@include breakpoint(bp-xxl) {
  .question {
    height: auto;

    &__content {
      margin-left: 2rem;
      font-size: 1.125rem;
    }

    // &__column {
    //   flex-direction: column;
    // }
  }
}

/* Estilos para dispositivos de tamaño grande */
@include breakpoint(bp-large) {
  .question {
    flex-direction: column;
    gap: 0;
  }
}

/* Estilos para dispositivos móviles */
@include breakpoint(bp-small) {
  .question {

    &__blueSheet3 {
      left: -188px;
    }

    &__content {
      font-size: 1rem;
    }
  }
}