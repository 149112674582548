@import './../../../styles/main';

.first-info {
  background:
    radial-gradient(31% 50% at 0% 100%, rgba(13, 39, 87, 0.6) 0%, rgba(3, 16, 45, 0.6) 150%),
    linear-gradient(180deg, rgba(3, 12, 26, 0.1) 1%, rgba(3, 12, 26, 0.1) 100%);
  position: relative;

  &__snake {
    position: absolute;
    top: 335px;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 900px;
    height: auto;
    z-index: 1;
    pointer-events: none;
  }

  &__blueSheet {
    position: absolute;
    bottom: -406px;
    left: -146px;
    height: auto;
    z-index: 1;
    pointer-events: none;
  }
}


/* Estilos para dispositivos de tamaño extra extra grande */
@include breakpoint(bp-xxl) {
  .first-info {
    &__snake {
      top: 450px;
    }
  }
}

/* Estilos para dispositivos de tamaño grande */
@include breakpoint(bp-large) {
  .first-info {
    &__snake {
      top: 28%;
    }

    &__blueSheet {
      bottom: -110px;
      left: -188px;
    }
  }
}

@include breakpoint(bp-medium) {
  .first-info {
    &__snake {
      top: 20%;
      width: auto;
    }

    &__blueSheet {
      bottom: -66px;
      left: -188px;
    }
  }
}

@include breakpoint(bp-small) {
  .first-info {
    &__snake {
      top: 20%;
      width: auto;
    }

    &__blueSheet {
      bottom: -100px;
      left: -188px;
    }
  }
}