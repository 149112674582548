@import './../../../styles/main';

.second-info {
    background:
        radial-gradient(31% 50% at 0% 0%, rgba(13, 39, 87, 0.6) 0%, rgba(3, 16, 45, 0.6) 150%),
        linear-gradient(180deg, rgba(3, 12, 26, 0.2) 1%, rgba(3, 12, 26, 0.2) 100%);
    display: flex;
    height: auto;
    flex-direction: column;
    gap: 3.125rem;
    position: relative;

    &__blueSheet2 {
        position: absolute;
        bottom: 38px;
        right: -90px;
        transform: scale(-1);
        z-index: 0;
        width: 600px;
    }

    &__columns {
        display: flex;
        gap: 13rem;
        flex-wrap: wrap;
        z-index: 1;
    }

    &__column {
        display: flex;
        text-align: left;
        flex-direction: column;
        align-items: center;
        flex: 1;
        z-index: 1;

        &-text {
            font-size: 1.375rem;
            color: #fff;
            line-height: 35px;
        }

        &-text-color {
            color: #a0586f;
        }
    }

    &__number {
        font-size: 40px;
        font-weight: 700;
        color: #59495e;
    }

    &__question {
        display: flex;
        flex-direction: column;
        z-index: 1;

        h2 {
            font-size: 1.875rem;
            color: #a0586f;
            line-height: 2.1875rem;
            font-weight: 600;
        }

        &-button {
            display: flex;
            gap: 1rem;
            margin-top: 1rem;
            justify-content: center;
            align-items: center;
        }

        &-btn {
            border: 3px solid #e1aac6;
            border-radius: 12px;
            color: aliceblue;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            padding: 3px 15px;
            text-transform: uppercase;
            background-color: #a0586f;
            width: 240px;

            &:hover {
                //   color: #a97d94;
                border: 3px solid #ed88bc;
                background-color: #d44877;
            }
        }
    }
}

/* Estilos para dispositivos de tamaño extra extra grande */
@include breakpoint(bp-xxl) {
    .second-info {
        height: auto;

        &__number {
            font-size: 30px;
        }

        &__columns {
            gap: 5rem;
        }

        &__column {
            width: 100%;

            &-text {
                line-height: 30px;
                font-size: 1.125rem;
            }
        }

        &__question {
            h2 {
                font-size: 1.5rem;
            }
        }
    }
}


/* Estilos para dispositivos de tamaño grande */
@include breakpoint(bp-large) {
    .second-info {
        &__blueSheet2 {
            position: absolute;
            bottom: -284px;
            right: -90px;
            transform: scale(-1);
            z-index: 1;
            width: 600px;
        }

        &__columns {
            flex-direction: column;
            gap: 3rem;
        }

        &__question {
            h2 {
                font-size: 1.35rem;
            }
        }
    }
}

/* Estilos para dispositivos tamaño mediano */
@include breakpoint(bp-medium) {
    .second-info {
        &__question {
            &-button {
                flex-direction: column;
            }
        }
    }
}

/* Estilos para dispositivos móviles */
@include breakpoint(bp-small) {
    .second-info {

        &__blueSheet2 {
            position: absolute;
            bottom: -145px;
            right: -153px;
            transform: scale(-1);
            z-index: 1;
            width: 600px;
        }

        &__columns {
            gap: 2rem;
        }

        &__question {
            &-button {
                flex-direction: column;
            }
        }

        &__column {
            &-text {
                font-size: 1rem;
            }
        }
    }
}